import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"
import SEO from "../components/seo"
import Layout from "../components/layout"
import Pagination from "../components/Pagination/pagination"
import ArticleCard from "../components/Article/Article"
import BannerBox from "../components/BannerBox/BannerBox"

import "../components/BlogsPage/BlogZone.scss"

import bannerLayer1 from "../assets/img/Blog/banner-layer1.jpg"
import bannerLayer2 from "../assets/img/Blog/banner-layer2.jpg"

import TabsCustom from "../components/TabsCustom/TabsCustom"

const bannerData = {
  title: ["Top news & tips", "from the space of", "AI solutions"],
}

const CategoriesPage = ({ data, pageContext }) => {
  const { t } = useTranslation()

  const [allPost, setAllPost] = useState([])
  const [listPost, setListPost] = useState([])
  const [firstArticle, setFirstArticle] = useState({})
  const [activeCategories, setActiveCategories] = useState([])
  const [currentPageSearch, setCurrentPageSearch] = useState(1)
  const [totalCountSearch, setTotalCountSearch] = useState(0)
  const [isSearch, setIsSearch] = useState(false)

  useEffect(() => {
    const dataBlog = data.allWpPost.nodes.map(({ excerpt, ...post }, index) => {
      const featuredImage = post.featuredImage?.node?.sourceUrl
      const thumbnail = featuredImage && featuredImage.indexOf("/wp-content/") === 0
        ? `https://neurond-wp.azurewebsites.net/${featuredImage}`
        : featuredImage
      const categorySlug = post.categories.nodes.map(node => node.slug)
      if (index === 0) {
        return {
          h2Title: post.title,
          description: [excerpt],
          imgLink: thumbnail,
          isReadMore: true,
          slug: post.slug,
          isBadge: true,
          categorySlug,
          categoryData: post.categories.nodes
        }
      }
      return {
        ...post,
        thumbnail,
        categorySlug,
        isBadge: true,
        shortContent: null,
        categoryData: post.categories.nodes
      }
    })

    setFirstArticle(dataBlog[0])
    setListPost(dataBlog.slice(1))

    const allCategory = data.allWpCategory.nodes.filter(category => category.count)
    setActiveCategories([{ slug: "all", name: "All" }, ...allCategory])

    const dataAllPost = pageContext.enBlogs?.map(({ excerpt, ...post }) => {
      const featuredImage = post.featuredImage?.node?.sourceUrl
      return {
        ...post,
        thumbnail:
          featuredImage && featuredImage.indexOf("/wp-content/") === 0
            ? `https://neurond-wp.azurewebsites.net/${featuredImage}`
            : featuredImage,
        categorySlug: post.categories.nodes.map(node => node.slug),
        isBadge: true,
        categoryData: post.categories.nodes
      }
    })

    setAllPost(dataAllPost || [])
  }, [])

  // const media =
  //   typeof window !== "undefined"
  //     ? matchMedia("(max-width: 768px)").matches
  //     : false

  return (
    <Layout>
      <SEO title={t("Blog")} canonical={data.site.siteMetadata.siteUrl + "/blog"}/>
      <section className={"blog-zone margin-bot-2"}>
        <section
          className="listHeader"
          style={{
            backgroundImage: `url(${bannerLayer1})`,
            backgroundSize: "cover",
            backgroundPosition: "right top",
          }}
        >
          <div
            className="listHeaderLayer2"
            style={{
              backgroundImage: `url(${bannerLayer2})`,
              backgroundSize: "cover",
            }}
          ></div>
          <div className="listHeaderBanner">
            <div className="wrapper">
              <BannerBox item={bannerData} />
            </div>
          </div>
        </section>
        <section className="wrapper">
          <article className="firstArticle">
            <ArticleCard item={firstArticle} index={1} />
          </article>
          {/* <div className="wrapper__row">
            {blogCard.map((blogData, index) => {
              return (
                <div
                  key={index}
                  className="wrapper__row__col-md-4 blog-custom-card"
                >
                  <CategoryCard item={blogData} key={index} isBadge={true} />
                </div>
              )
            })}
          </div> */}
          <TabsCustom
            categoriesData={activeCategories}
            tabData={listPost}
            currentTabSlug={"all"}
            allPost={allPost}
            setIsSearch={setIsSearch}
            setTotalCountSearch={setTotalCountSearch}
            currentPageSearch={currentPageSearch}
            setCurrentPageSearch={setCurrentPageSearch}
            limit={pageContext?.limit}
          />

          <Pagination
            pageContext={pageContext}
            prefix="blog"
            totalCount={data.allWpPost.totalCount}
            totalCountSearch={totalCountSearch}
            setCurrentPageSearch={setCurrentPageSearch}
            currentPageSearch={currentPageSearch}
            isSearch={isSearch}
          />
        </section>
      </section>
    </Layout>
  )
}

export default CategoriesPage

export const query = graphql`
  query($limit: Int, $skip: Int) {
    allWpCategory {
      nodes {
        slug
        name
        count
      }
    }
    allWpPost(limit: $limit, skip: $skip, sort: { fields: date, order: DESC }) {
      nodes {
        title
        excerpt
        featuredImage {
          node {
            sourceUrl
          }
        }
        slug
        categories {
          nodes {
            slug
            name
            description
            CategoryCustomFields {
              color
              thumbnail {
                sourceUrl
              }
            }
          }
        }
      }
      totalCount
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
