import React, { useState, useEffect } from "react"
// import PropTypes from "prop-types"
import { Link } from "gatsby"
import "./TabsCustom.scss"
import CategoryCard from "../Cards/CategoryCard"
import searchIcon from "../../assets/icon/search.png"

export default function TabsCustom({
  categoriesData,
  tabData,
  currentTabSlug,
  allPost,
  currentPageSearch,
  setCurrentPageSearch,
  setTotalCountSearch,
  setIsSearch,
  limit
}) {
  const [searchValue, setSearchValue] = useState("")
  const [filteredData, setFilteredData] = useState([])

  // const [activeTab, setActiveTab] = useState(`${currentTabSlug}`)
  // const [displayData, setDisplayData] = useState(tabData)
  // function handleClick(slug) {
  //   setActiveTab(slug)
  //   if (slug === "all") {
  //     setDisplayData(tabData)
  //   } else {
  //     let filteredData = tabData.filter(x => x.categorySlug === slug)
  //     setDisplayData(filteredData)
  //   }
  // }

  useEffect(() => {
    if (searchValue.data) {
      const listPostSearch = allPost?.filter(item => {
        return item.title.toUpperCase().includes(searchValue.data.toUpperCase())
      }) || []
      setFilteredData(listPostSearch)
      setTotalCountSearch(listPostSearch?.length)
      setIsSearch(true)
      setCurrentPageSearch(1)
    } else {
      setIsSearch(false)
      setFilteredData([])
      setTotalCountSearch(0)
    }
  }, [searchValue, tabData])

  function handleSubmit(e) {
    e.persist()
    setSearchValue(values => ({
      ...values,
      data: e.target.value,
    }))
  }

  const dataPostShow = searchValue?.data ? filteredData?.slice((currentPageSearch - 1) * limit , currentPageSearch * limit) : tabData

  const renderEmpty = () => {
    if (searchValue.data) {
      return (
        <div className="empty-post">
          <p>Sorry, but nothing matched your search terms.</p>
          <p>Please try again with some different keywords.</p>
        </div>
      )
    }
    return (
      <div className="empty-post">
        <p>Sorry, the category is empty.</p>
        <p>Please choose another category.</p>
      </div>
    )
  }

  return (
    <div className="Tabs">
      <div className="tabNav">
        <ul className="navSection">
          {categoriesData.map((item, index) => {
            return (
              <Link
                key={item.slug}
                className="custom"
                to={`${
                  item.slug === "all" ? "/blog" : `/categories/${item.slug}`
                }`}
              >
                <li
                  key={index}
                  className={currentTabSlug === `${item.slug}` ? "active" : ""}
                >
                  {item.name}
                </li>
              </Link>
            )
          })}
        </ul>
        <div className="finding">
          <input
            id="search-value"
            className="findIcon"
            type="text"
            style={{ backgroundImage: `url(${searchIcon})` }}
            name="searchValue"
            value={searchValue.data}
            onChange={handleSubmit}
            autoComplete="off"
          />
        </div>
      </div>

      <div className="outlet">
        {dataPostShow.length ? dataPostShow.map((blogData, index) => {
          return (
            <div key={index} className="outlet__col-md-4 blog-custom-card">
              <CategoryCard item={blogData} key={index} />
            </div>
          )
        }) : renderEmpty()}
      </div>
    </div>
  )
}
