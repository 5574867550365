import React from "react"
import { Link } from "gatsby"
import classnames from "classnames"
import { usePagination, DOTS } from "./hook/usePagination"
import "./pagination.scss"

const Pagination = ({
  pageContext,
  prefix,
  totalCount,
  className,
  siblingCount = 1,
  totalCountSearch,
  setCurrentPageSearch,
  currentPageSearch,
  isSearch
}) => {
  const currentPage = isSearch ? currentPageSearch : pageContext?.currentPage
  const { limit } = pageContext
  const pathPrefix = pageContext.slug ? `${prefix}/${pageContext.slug}` : prefix
  const pageSize = limit
  const paginationRange = usePagination({
    currentPage,
    totalCount: isSearch ? totalCountSearch : totalCount,
    siblingCount,
    pageSize,
  })

  const currentLanguage = pageContext?.i18n?.language
  const currentLanguagePrefix = currentLanguage === "jp" ? "/jp" : ""

  if (currentPage === 0 || paginationRange?.length < 2) {
    return null
  }

  let lastPage = paginationRange[paginationRange.length - 1]
  let urlPrev =
    currentPage - 1 === 1
      ? `${currentLanguagePrefix}/${pathPrefix}`
      : `${currentLanguagePrefix}/${pathPrefix}/${currentPage - 1}`

  return (
    <div className="center pagination-custom margin-bot-2">
      <ul
        className={classnames("pagination-container", {
          [className]: className,
        })}
      >
        {currentPage !== 1 ? (
          <li className={classnames("pagination-item-arrow ")} rel="prev">
            {isSearch
              ? (<p
                  className="pagination-next h4-text pagination-search" rel="next"
                  onClick={() => setCurrentPageSearch(currentPage - 1)}
                >
                  Previous
                </p>)
              : (<Link className="pagination-prev h4-text" to={urlPrev}>
                  Previous
                </Link>)
            }
          </li>
        ) : (
          <li className={classnames("pagination-item-arrow")}>
            <p className="pagination-next h4-text custom" rel="next">
              Previous
            </p>
          </li>
        )}

        {paginationRange.map(pageNumber => {
          if (pageNumber === DOTS) {
            return <li key="item-dot" className="pagination-item dots">&#8230;</li>
          }
          let urlMid =
            pageNumber === 1
              ? `${currentLanguagePrefix}/${pathPrefix}`
              : `${currentLanguagePrefix}/${pathPrefix}/${pageNumber}`
          return (
            <li
              key={pageNumber}
              className={classnames("pagination-item", {
                selected: pageNumber === currentPage,
              })}
            >
              {isSearch
                ? (<p
                  className="pagination-link h4-text center pagination-search"
                  onClick={() => setCurrentPageSearch(pageNumber)}
                >
                  {pageNumber}
                </p>)
                : (<Link className="pagination-link h4-text center" to={urlMid}>
                  {pageNumber}
                </Link>)
              }
            </li>
          )
        })}
        {currentPage !== lastPage ? (
          <li className={classnames("pagination-item-arrow")}>
            {isSearch
              ? (<p
                className="pagination-next h4-text pagination-search" rel="next"
                onClick={() => setCurrentPageSearch(currentPage + 1)}
              >
                Next
              </p>)
              : (<Link
                className="pagination-next h4-text"
                rel="next"
                to={`${currentLanguagePrefix}/${pathPrefix}/${currentPage + 1}`}
              >
                Next
              </Link>)
            }
          </li>
        ) : (
          <li className={classnames("pagination-item-arrow")}>
            <p className="pagination-next h4-text custom" rel="next">
              Next
            </p>
          </li>
        )}
      </ul>
    </div>
  )
}

export default Pagination
